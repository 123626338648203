/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./index.scss";
import { LocalizationProvider, DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Alert, AlertTitle, Snackbar, TextField } from "@mui/material";
import Layout from "../../components/Layout";
import EmployeeDetailsCard from "./EmployeeDetailsCard";
import EmployeeStore from "./Store";
import { employeeList } from "../../utils/appConstants";
import { findEmployeeName, isNotEmptyObj } from "../../utils/helper";
import { EmployeeDetailsProps } from "./types";

const EmployeesContent = (): JSX.Element => {
  const today = new Date();
  const [selectedMonth, setSelectedMonth] = useState<Date>(
    new Date(today.getFullYear(), (today.getMonth() - 1), 1)
  );

  const [employeeDetails, setEmployeeDetails] = useState<
    EmployeeDetailsProps[]
  >([]);

  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showRecordsErrorAlert, setShowRecordsErrorAlert] = useState(false);

  const {
    getEmployeeAttendances,
    getEmployeeAttendanceSuccess,
    getEmployeeAttendanceError,
    clearGetEmployeeAttendacesResponses,
    getEmployeeRecordsError,
    clearGetEmployeeRecordsResponses,
  } = EmployeeStore();

  const handleDateChange = (newValue: Date | null) => {
    if (newValue != null) {
      setSelectedMonth(newValue);
      getEmployeeAttendances({
        month: newValue.toLocaleDateString("en-IN"),
        listOfEmployees: employeeList.map((item) => item.code),
      });
    }
  };

  useEffect(() => {
    getEmployeeAttendances({
      month: selectedMonth.toLocaleDateString("en-IN"),
      listOfEmployees: employeeList.map((item) => item.code),
    });
  }, []);

  useEffect(() => {
    if (isNotEmptyObj(getEmployeeAttendanceSuccess)) {
      const details: EmployeeDetailsProps[] | undefined =
        getEmployeeAttendanceSuccess?.employeeDetails.map((item) => {
          return {
            employeeId: item.employeeId,
            employeeName: findEmployeeName(item.employeeId),
            month: selectedMonth.toLocaleDateString(),
            year: selectedMonth.getFullYear(),
            monthName: selectedMonth.toLocaleString("default", {
              month: "long",
            }),
            totalHours: item.totalHours,
            totalAmount: item.totalAmount,
            totalDiff: item.totalDiff,
            contactNumber: "",
            totalAdvancePaid: item.totalAdvancePaid,
            balance: item.balance,
          };
        });
      if (details) setEmployeeDetails(details);
      clearGetEmployeeAttendacesResponses();
      clearGetEmployeeRecordsResponses();
    }
    if (isNotEmptyObj(getEmployeeAttendanceError)) {
      setShowErrorAlert(true);
      clearGetEmployeeAttendacesResponses();
      clearGetEmployeeRecordsResponses();
    }
  }, [getEmployeeAttendanceSuccess, getEmployeeAttendanceError]);

  useEffect(() => {
    if (isNotEmptyObj(getEmployeeRecordsError)) {
      setShowRecordsErrorAlert(true);
      clearGetEmployeeRecordsResponses();
    }
  }, [getEmployeeRecordsError]);

  return (
    <div className="employee-page-container">
      {showErrorAlert && (
        <Snackbar
          open={showErrorAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowErrorAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowErrorAlert(false)}
            className="sucess-alert"
          >
            <AlertTitle>Error</AlertTitle>
            Couldn't fetch employee data.
          </Alert>
        </Snackbar>
      )}
      {showRecordsErrorAlert && (
        <Snackbar
          open={showRecordsErrorAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowRecordsErrorAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowRecordsErrorAlert(false)}
            className="sucess-alert"
          >
            <AlertTitle>Error</AlertTitle>
            Couldn't fetch employee attendance and salary records.
          </Alert>
        </Snackbar>
      )}
      <div className="employee-page-headers">
        <div className="heading-3 text-bold">{`Employee Records of ${selectedMonth.toLocaleString(
          "default",
          {
            month: "long",
            year: "numeric",
          }
        )}`}</div>
        <div>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              views={["year", "month"]}
              openTo="month"
              value={selectedMonth}
              label="Change Month"
              onChange={handleDateChange}
              renderInput={(params: any) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div className="employee-details-cards-container">
        {isNotEmptyObj(employeeDetails) &&
          employeeDetails.length > 0 &&
          employeeDetails.map((item, index) => (
            <EmployeeDetailsCard
              key={`${item.employeeId}+${index}+${selectedMonth.toString()}`}
              {...item}
            />
          ))}
      </div>
    </div>
  );
};

const Employees: React.FunctionComponent = () => {
  return (
    <div>
      <Layout content={<EmployeesContent />} />
    </div>
  );
};

export default Employees;
