/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import {
  isNotEmptyObj,
  convertToAmountString,
  paginateList,
} from "../../utils/helper";
import { CustomersListResponseType, CustomersListType } from "../types/types";
import "./index.scss";
import CustomerState from "./Store";
import Table, { ColumnProps } from "../../components/Table";
import { InputAdornment, TextField } from "@mui/material";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";

const CustomersListContent = (): JSX.Element => {
  const {
    customersListResponse,
    customersListResponseError,
    getCustomersList,
  } = CustomerState();

  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(20);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [customersList, setCustomersList] = useState<CustomersListType[]>();
  const [tableDataSource, setTableDataSource] = useState<Array<any>>([]);

  useEffect(() => {
    const payload = {
      employeeId: "",
      entriesPerPage: entriesPerPage,
      pageNumber: pageNumber,
    };
    getCustomersList(payload);
  }, []);

  useEffect(() => {
    if (isNotEmptyObj(customersListResponse)) {
      const { list } = customersListResponse as CustomersListResponseType;
      const {
        data,
        pageNumber: pageNo,
        entriesPerPage: entriesInPage,
        totalCount: total,
      } = paginateList(list, pageNumber, entriesPerPage);
      setCustomersList(data);
      setPageNumber(pageNo);
      setEntriesPerPage(entriesInPage);
      setTotalCount(total);
    }
  }, [customersListResponse, customersListResponseError]);

  const pushToDetails = (index: number): void => {
    navigate(
      `/customerDetails/${customersList ? customersList[index].customerId : ""}`
    );
  };

  useEffect(() => {
    if (customersList) {
      setTableDataSource(
        customersList.map((item) => ({
          customerName: item.customerName,
          contact: item.contact,
          lastCreditOn: item.lastCreditOn,
          lastPaymentOn: item.lastPaymentOn,
          creditAmount: convertToAmountString(item.lastCreditAmount),
          paymentAmount: convertToAmountString(item.lastPaymentAmount),
          outstanding: convertToAmountString(item.amountOutStanding),
        }))
      );
    }
  }, [customersList]);

  const columnData: ColumnProps[] = [
    {
      key: "customerName",
      title: "Customer",
      alignment: "left",
    },
    {
      key: "contact",
      title: "Contact",
      alignment: "center",
    },
    {
      key: "lastCreditOn",
      title: "Last Credit On",
      alignment: "center",
    },
    {
      key: "lastPaymentOn",
      title: "Last Payment On",
      alignment: "center",
    },
    {
      key: "creditAmount",
      title: "Last Credit",
      alignment: "right",
    },
    {
      key: "paymentAmount",
      title: "Last Payment",
      alignment: "right",
    },
    {
      key: "outstanding",
      title: "Outstanding",
      alignment: "right",
    },
  ];

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ): void => {
    if (event) {
      const { list } = customersListResponse as CustomersListResponseType;
      const { data } = paginateList(list, page, entriesPerPage);
      setCustomersList(data);
      setPageNumber(page);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEntriesPerPage(+event.target.value);
    setPageNumber(0);
    const payload = {
      employeeId: "",
      entriesPerPage: entriesPerPage,
      pageNumber: 0,
    };
    getCustomersList(payload);
    setPageNumber(0);
  };

  const handleSearch = (keyword: string): void => {
    const { list } = customersListResponse as CustomersListResponseType;
    const filteredList = list.filter((item) =>
      item.customerName
        .toLocaleLowerCase()
        .includes(keyword.toLocaleLowerCase())
    );
    const {
      data,
      pageNumber: pageNo,
      entriesPerPage: entriesInPage,
      totalCount: total,
    } = paginateList(filteredList, 0, entriesPerPage);
    setCustomersList(data);
    setPageNumber(pageNo);
    setEntriesPerPage(entriesInPage);
    setTotalCount(total);
  };

  return (
    <div className="customers-list-container">
      <div className="customers-list-header-container is-flex is-align-items-center is-justify-content-space-between">
        <div className="customers-list-header">Customers</div>
        <div className="">
          <TextField
            id="expense-description"
            label="Search by Customer Name"
            // variant="standard"
            size="small"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
              handleSearch(e.target.value)
            }
            sx={{ width: 390, height: 30 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonSearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
      <div className="customers-list-table-container">
        <Table
          columnData={columnData}
          dataSource={tableDataSource}
          isPaginated
          pagination={{
            pageNumber: pageNumber,
            onPageChange: handlePageChange,
            entriesPerPage: entriesPerPage,
            totalCount: totalCount,
            onRowsPerPageChange: handleChangeRowsPerPage,
          }}
          rowClick={(index: number): void => {
            pushToDetails(index);
          }}
          style={{ maxHeight: "calc(100vh - 178px)" }}
        />
      </div>
    </div>
  );
};

const CustomersList: React.FunctionComponent = () => {
  return (
    <div>
      <Layout content={<CustomersListContent />} />
    </div>
  );
};

export default CustomersList;
