/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout";
import { useFormik } from "formik";
import {
  IconButton,
  TextField,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Autocomplete,
  Snackbar,
  Alert,
  AlertTitle,
} from "@mui/material";
import { TimePicker } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import "./index.scss";
import Modal from "../../components/Modal";
import ADDICON from "../../images/add-button.svg";
import {
  AddCreditCustomerType,
  ExpenseType,
  PaymentsRecievedType,
  CreateDailySaleRecordRequestType,
  ProductType,
} from "../types/types";
import AddCustomer from "../Customers/AddCustomerModal";
import AddCreditSales from "./AddCreditSales";
import AddSalesState from "./Store";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  paymentModeList,
  employeeList,
  expenseTypeList,
} from "../../utils/appConstants";
import DropDownInput from "../../components/DropdownInput";
import SaveIcon from "@mui/icons-material/Save";
import { AccountCircle } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { isNotEmptyObj, stripZerosAndAlpha } from "../../utils/helper";
import PriceModal from "./PriceModal";
import { NozzleReadings } from "./types";

const AddSaleDetailsForm = (): JSX.Element => {
  const navigate = useNavigate();

  const now = new Date();
  const {
    creditSalesList,
    setCreditSalesList,
    clearCreditSalesList,
    customersList,
    getAllCustomer,
    getProductList,
    productList,
    productListResponseError,
    clearProductListResponse,
    callCreateDailySalesRecord,
    createDailySalesRecordRespData,
    createDailySalesRecordRespError,
    clearsetCreateDailySalesRecordResp,
    fetchTodaysPrice,
    fetchPriceResponse,
    fetchPriceResponseError,
    clearFetchPriceResponse,
  } = AddSalesState();

  const [cashTotal, setCashTotal] = useState<number>(0);
  const [totalReceivables, setTotalReceivables] = useState<number>(0);
  const [totalRecieved, setTotalRecieved] = useState<number>(0);
  const [showTotalModal, setShowTotalModal] = useState(false);
  const [showAddCreditModal, setShowAddCreditModal] = useState(false);
  const [nozzleReadings, setNozzleReadings] = useState<NozzleReadings[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<ProductType>();

  const [showPriceModal, setShowPriceModal] = useState(false);

  const [showValidationAlert, setShowValidationAlert] = useState(false);

  const [validationMessage, setValidationMessage] = useState("");

  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [showPriceAlert, setShowPriceAlert] = useState(false);

  const [showTestForm, setShowTestForm] = useState(false);

  const [showAddCustomer, setShowAddCustomer] = useState(false);

  const [showExpenseForm, setShowExpenseForm] = useState(false);

  const [showRecievedForm, setShowRecievedForm] = useState(false);

  const [expenseList, setExpenseList] = useState<ExpenseType[]>([]);

  const [date, setDate] = useState<Date | null>(new Date());

  const [startTime, setStartTime] = useState<Date | null>(
    new Date(now.setHours(6, 0, 0))
  );

  const [endTime, setEndTime] = useState<Date | null>(
    new Date(new Date().setMinutes(0, 0))
  );

  const [paymentRecievedList, setPaymentRecievedList] = useState<
    PaymentsRecievedType[]
  >([]);

  const [diff, setDiff] = useState<number>(0);

  const [selectedCustomer, setSelectedCustomer] =
    useState<AddCreditCustomerType>();

  const formik = useFormik({
    initialValues: {
      employeeId: "",
      employeeName: "",
      startTime: new Date(now.setHours(6, 0, 0)).toLocaleString("en-IN") || "",
      endTime: now.toLocaleString("en-IN"),
      date: now.toLocaleDateString("en-IN"),
      startReadingNozzle: "",
      closeReadingNozzle: "",
      cash2000: "",
      cash500: "",
      cash100: "",
      cash200: "",
      cash50: "",
      cash20: "",
      cash10: "",
      coins: "",
      advancePaid: "",
      cardSales: "",
      upiSales: "",
      cashTotal: "",
      testMs: "",
      testHsd: "",
      expenseDescription: "",
      expenseAmount: "",
      expenseType: "",
      salaryEmployeeId: "",
      salaryEmployeeName: "",
      customerId: "",
      customerName: "",
      amount: "",
      paymentMode: "",
    },
    onSubmit: (value) => {
      console.log("");
    },
  });

  const handleChange = (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>,
    isAmount: boolean
  ): void => {
    let input = event.target.value;
    if (isAmount) input = stripZerosAndAlpha(event.target.value);
    formik.setFieldValue(type, input);
  };

  const updateTotalCash = (): void => {
    let total = 0;
    total += Number(formik.values.cash2000) * 2000;
    total += Number(formik.values.cash500) * 500;
    total += Number(formik.values.cash200) * 200;
    total += Number(formik.values.cash100) * 100;
    total += Number(formik.values.cash50) * 50;
    total += Number(formik.values.cash20) * 20;
    total += Number(formik.values.cash10) * 10;
    total += Number(formik.values.coins);
    total += Number(formik.values.advancePaid);
    setCashTotal(total);
  };

  const handleCashChange = (
    type: string,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    formik.setFieldValue(type, event.target.value);
  };

  const handleDateChange = (newValue: Date | null) => {
    if (newValue != null) {
      formik.setFieldValue("date", newValue?.toLocaleDateString("en-IN"));
      setDate(newValue);
      getProductList(newValue);
      clearFetchPriceResponse();
    }
  };

  const handleTimeChange = (time: Date | null, type: string) => {
    if (time != null) {
      formik.setFieldValue(type, time?.toLocaleString("en-IN"));
      switch (type) {
        case "startTime":
          setStartTime(time);
          return;
        case "endTime":
          setEndTime(time);
          return;
      }
    }
  };

  useEffect(() => {

    if (!isNotEmptyObj(fetchPriceResponse)) {
      fetchTodaysPrice();
    }

    getAllCustomer();
    getProductList(date || new Date());
  }, []);

  useEffect(() => {
    if (
      isNotEmptyObj(fetchPriceResponseError) &&
      !isNotEmptyObj(fetchPriceResponse)
    ) {
      setShowPriceModal(true);
    }
  }, [fetchPriceResponseError]);

  useEffect(() => {
    updateTotalCash();
  }, [
    formik.values.cash2000,
    formik.values.cash500,
    formik.values.cash200,
    formik.values.cash100,
    formik.values.cash50,
    formik.values.cash20,
    formik.values.cash10,
    formik.values.coins,
    formik.values.advancePaid,
  ]);

  useEffect(() => {
    if (isNotEmptyObj(createDailySalesRecordRespData)) {
      setShowSuccessAlert(true);
      navigate("/salesList");
      clearsetCreateDailySalesRecordResp();
      clearCreditSalesList();
      clearProductListResponse();
    }
    if (isNotEmptyObj(createDailySalesRecordRespError)) {
      setShowErrorAlert(true);
      clearsetCreateDailySalesRecordResp();
    }
  }, [createDailySalesRecordRespData, createDailySalesRecordRespError]);

  useEffect(() => {
    if (isNotEmptyObj(productListResponseError)) {
      setShowPriceAlert(true);
      setShowPriceModal(true);
    }
  }, [productListResponseError]);

  const addExpense = (): void => {
    if (formik.values.expenseType === "Salary") {
      setExpenseList([
        ...expenseList,
        {
          expenseId: Math.random().toString(36).substring(2, 7),
          expenseType:
            expenseTypeList.find(
              (item) => item.name === formik.values.expenseType
            )?.code || "",
          description: formik.values.expenseDescription,
          employeeId: formik.values.salaryEmployeeId,
          employeeName: formik.values.salaryEmployeeName,
          amount: formik.values.expenseAmount,
        },
      ]);
    } else {
      setExpenseList([
        ...expenseList,
        {
          expenseId: Math.random().toString(36).substring(2, 7),
          expenseType:
            expenseTypeList.find(
              (item) => item.name === formik.values.expenseType
            )?.code || "",
          description: formik.values.expenseDescription,
          amount: formik.values.expenseAmount,
        },
      ]);
    }
    formik.setFieldValue("expenseDescription", "");
    formik.setFieldValue("expenseAmount", "");
    formik.setFieldValue("expenseType", "");
    formik.setFieldValue("salaryEmployeeId", "");
    formik.setFieldValue("salaryEmployeeName", "");
  };

  const deleteExpense = (id: string) => {
    const filteredList = expenseList.filter((item) => item.expenseId !== id);
    setExpenseList(filteredList);
  };

  const addPaymentRecieved = (): void => {
    if (selectedCustomer) {
      setPaymentRecievedList([
        ...paymentRecievedList,
        {
          paymentId: Math.random().toString(36).substring(2, 7),
          customerId: selectedCustomer.customerId,
          customerName: selectedCustomer.customerName,
          amount: formik.values.amount,
          paymentMode: formik.values.paymentMode,
        },
      ]);
    }
    formik.setFieldValue("customerId", "");
    formik.setFieldValue("customerName", "");
    formik.setFieldValue("amount", "");
    formik.setFieldValue("paymentMode", "");
    setSelectedCustomer({} as AddCreditCustomerType);
  };

  const deletePaymentRecieved = (id: string): void => {
    const filteredList = paymentRecievedList.filter(
      (item) => item.paymentId !== id
    );
    setPaymentRecievedList(filteredList);
  };

  const handleCustomerSelection = (
    customer: AddCreditCustomerType | null
  ): void => {
    if (customer) {
      setSelectedCustomer(customer);
      formik.setFieldValue("customerId", customer.customerId);
      formik.setFieldValue("customerName", customer.customerName);
    }
  };

  const handlePaymentModeSelection = (value: string): void => {
    const paymentmode = paymentModeList.find((item) => item.code === value);
    if (paymentmode) formik.setFieldValue("paymentMode", paymentmode.name);
  };

  const handleExpenseTypeSelection = (value: string): void => {
    const expenseType = expenseTypeList.find((item) => item.code === value);
    if (expenseType) formik.setFieldValue("expenseType", expenseType.name);
    if (expenseType && expenseType.name === "Discount")
      formik.setFieldValue("expenseDescription", "Discount");
  };

  const handleSalaryEmployeeSelection = (value: string): void => {
    const employee = employeeList.find((item) => item.code === value);
    if (employee) {
      formik.setFieldValue("salaryEmployeeName", employee.name);
      formik.setFieldValue("salaryEmployeeId", employee.code);
      formik.setFieldValue("expenseDescription", `${employee.name} Salary`);
    }
  };

  const handleEmployeeSelection = (value: string): void => {
    const employee = employeeList.find((item) => item.code === value);
    if (employee) formik.setFieldValue("employeeName", employee.name);
    if (employee) formik.setFieldValue("employeeId", employee.code);
  };

  const deleteCreditSale = (saleId: string): void => {
    const filteredList = creditSalesList.filter(
      (item) => item.saleId !== saleId
    );
    setCreditSalesList(filteredList);
  };

  const calculateTotal = (): void => {
    const msPrice = productList.find((item) => item.productCode === "1")?.price;
    const hsdPrice = productList.find(
      (item) => item.productCode === "2"
    )?.price;

    let msSale = 0
    let hsdSale = 0

    if (nozzleReadings.length > 0) {
      nozzleReadings.forEach((item) => {
        if (item.product === 'MS')
          msSale += item.total
        else hsdSale += item.total
      })

    }
    let totalReceivableAmount =
      msSale * Number(msPrice) + hsdSale * Number(hsdPrice);
    paymentRecievedList.forEach(
      (item) => (totalReceivableAmount += Number(item.amount))
    );
    expenseList.forEach(
      (item) => (totalReceivableAmount -= Number(item.amount))
    );

    totalReceivableAmount -=
      Number(formik.values.testMs) + Number(formik.values.testHsd);

    if (!isNaN(totalReceivableAmount))
      setTotalReceivables(Number(totalReceivableAmount.toFixed(2)));

    let totalAmountRecieved =
      cashTotal +
      Number(formik.values.cardSales) +
      Number(formik.values.upiSales);
    creditSalesList.forEach(
      (item) => (totalAmountRecieved += Number(item.amount))
    );

    if (!isNaN(totalAmountRecieved))
      setTotalRecieved(Number(totalAmountRecieved.toFixed(2)));
    const dif = totalReceivableAmount - totalAmountRecieved;
    if (!isNaN(dif)) setDiff(Number(dif.toFixed(0)));
  };

  const isRecordSubmissble = (): boolean =>
    isNotEmptyObj(formik.values.employeeId) &&
    isNotEmptyObj(formik.values.employeeName) &&
    isNotEmptyObj(formik.values.startTime) &&
    isNotEmptyObj(formik.values.endTime) &&
    isNotEmptyObj(formik.values.date) &&
    isNotEmptyObj(nozzleReadings) &&
    cashTotal !== 0;

  const renderTotalModal = (): JSX.Element => {
    return (
      <div className="total-modal-container">
        <div className="total-container is-flex is-align-items-center">
          <div className="total-title">Total Receivable Amount: </div>
          <div className="total-amount">
            {totalReceivables.toLocaleString("en-IN")}
          </div>
        </div>
        <div className="total-container is-flex is-align-items-center">
          <div className="total-title">Total Amount Received: </div>
          <div className="total-amount">
            {totalRecieved.toLocaleString("en-IN")}
          </div>
        </div>
        <div className="total-container is-flex is-align-items-center">
          <div className="total-title">Difference: </div>
          <div className={`total-amount ${diff > 0 ? "redded" : "greened"}`}>
            {diff.toLocaleString("en-IN")}
          </div>
        </div>
      </div>
    );
  };
  const createDailySalesRecord = (): void => {
    const payload: CreateDailySaleRecordRequestType = {
      employeeId: formik.values.employeeId,
      employeeName: formik.values.employeeName,
      startTime: formik.values.startTime,
      endTime: formik.values.endTime,
      date: formik.values.date,
      nozzleReadings: nozzleReadings,
      cash2000: formik.values.cash2000,
      cash500: formik.values.cash500,
      cash100: formik.values.cash100,
      cash200: formik.values.cash200,
      cash50: formik.values.cash50,
      cash20: formik.values.cash20,
      cash10: formik.values.cash10,
      coins: formik.values.coins,
      advancePaid: formik.values.advancePaid,
      cardSales: formik.values.cardSales,
      upiSales: formik.values.upiSales,
      testMs: formik.values.testMs,
      testHsd: formik.values.testHsd,
      msPrice:
        productList.find((item) => item.productCode === "1")?.price || "",
      hsdPrice:
        productList.find((item) => item.productCode === "2")?.price || "",
      creditSalesRequestList: creditSalesList,
      expenseRequestList: expenseList,
      paymentReceivedRequestDTO: paymentRecievedList,
      totalAmountReceived: totalRecieved.toString(),
      totalAmountReceivable: totalReceivables.toString(),
      diff: diff.toString(),
    };
    callCreateDailySalesRecord(payload);
  };

  const getExpenseType = (value: string): string => {
    const expense = expenseTypeList.find((item) => item.code === value);
    return expense ? expense.name : "";
  };

  const addReading = () => {
    if (selectedProduct) {
      setNozzleReadings([
        ...nozzleReadings,
        {
          id: Math.random().toString(36).substring(2, 7),
          price: Number(selectedProduct.price),
          product: selectedProduct.productName,
          starting: Number(formik.values.startReadingNozzle),
          closing: Number(formik.values.closeReadingNozzle),
          total: Number(formik.values.closeReadingNozzle) - Number(formik.values.startReadingNozzle)
        }
      ])
      formik.setFieldValue("startReadingNozzle", "");
      formik.setFieldValue("closeReadingNozzle", "");
      setSelectedProduct(productList[0]);
    }
  }

  const deleteNozzleReading = (id: string) => {
    const filteredList = nozzleReadings.filter(
      (item) => item.id !== id
    );
    setNozzleReadings(filteredList);
  }

  return (
    <>
      <Modal
        show={showTotalModal}
        content={renderTotalModal()}
        closeModal={(): void => setShowTotalModal(false)}
        contentClassName="total-modal-content"
      />
      {showPriceModal && (
        <PriceModal
          closeModal={(): void => {
            setShowPriceModal(false);
            // getProductList(date || new Date());
          }}
          date={
            date &&
              date?.toLocaleDateString("en-IN") !== new Date().toLocaleDateString("en-IN")
              ? date
              : undefined
          }
        />
      )}
      {showAddCreditModal && (
        <AddCreditSales
          closeModal={(): void => setShowAddCreditModal(false)}
          showAddCustomer={(): void => {
            setShowAddCustomer(true);
          }}
        />
      )}
      {showAddCustomer && (
        <AddCustomer
          closeModal={(): void => {
            setShowAddCustomer(false);
            getAllCustomer();
          }}
        />
      )}
      {showValidationAlert && (
        <Snackbar
          open={showValidationAlert}
          autoHideDuration={7000}
          onClose={(): void => setShowValidationAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="warning"
            onClose={(): void => setShowValidationAlert(false)}
          >
            <AlertTitle>Warning</AlertTitle>
            {validationMessage}
          </Alert>
        </Snackbar>
      )}
      {showSuccessAlert && (
        <Snackbar
          open={showSuccessAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowSuccessAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="success"
            onClose={(): void => setShowSuccessAlert(false)}
          >
            <AlertTitle>Success</AlertTitle>
            Successfully added your record.
          </Alert>
        </Snackbar>
      )}
      {showErrorAlert && (
        <Snackbar
          open={showErrorAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowErrorAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowErrorAlert(false)}
          >
            <AlertTitle>Failed to add your record</AlertTitle>
            {`${isNotEmptyObj(createDailySalesRecordRespError)
              ? createDailySalesRecordRespError?.error
              : "Error"
              }  occured during the process. Please try again.`}
          </Alert>
        </Snackbar>
      )}
      {showPriceAlert && (
        <Snackbar
          open={showPriceAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowPriceAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowPriceAlert(false)}
          >
            <AlertTitle>{`Failed to load price for ${date?.toLocaleDateString("en-IN")}`}</AlertTitle>
          </Alert>
        </Snackbar>
      )}
      {/* {showErrorAlert && (
        <Snackbar
          open={showErrorAlert}
          autoHideDuration={4000}
          onClose={(): void => setShowErrorAlert(false)}
          message="Note archived"
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            severity="error"
            onClose={(): void => setShowErrorAlert(false)}
          >
            <AlertTitle>Failed to add customer</AlertTitle>
            Error while creating customer. Please try again later
          </Alert>
        </Snackbar>
      )} */}

      <div className="">
        <div className="add-sales-details">
          <div className="add-details-header-container is-flex is-justify-content-space-between">
            <div className="add-details-header">Daily Sales Record</div>
            <div className="add-details-button-container is-flex is-justify-content-flex-end">
              <div className="button-container">
                <Button
                  variant="outlined"
                  onClick={(): void => {
                    calculateTotal();
                    setShowTotalModal(true);
                  }}
                >
                  Show Total
                </Button>
              </div>
              <div className="button-container">
                <Button
                  variant="contained"
                  onClick={(): void => {
                    calculateTotal();
                    createDailySalesRecord();
                  }}
                  color="success"
                  startIcon={<SaveIcon />}
                  disabled={!isRecordSubmissble()}
                >
                  Save Record
                </Button>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <DropDownInput
                value={formik.values.employeeName}
                items={employeeList}
                placeholder="Select Person"
                name="select-person-name"
                onChange={handleEmployeeSelection}
                width={300}
                icon={<AccountCircle />}
              />
            </div>
          </div>
          <div className="columns">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="column">
                <DesktopDatePicker
                  value={date}
                  label="Date desktop"
                  inputFormat="dd/MM/yyyy"
                  onChange={handleDateChange}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </div>
              <div className="column">
                <TimePicker
                  value={startTime}
                  label="Start Time"
                  onChange={(value: any) => handleTimeChange(value, "startTime")}
                  renderInput={(params: any) => <TextField {...params} />}
                  views={["hours"]}
                />
              </div>
              <div className="column">
                <TimePicker
                  value={endTime}
                  label="End Time"
                  onChange={(value: any) => handleTimeChange(value, "endTime")}
                  renderInput={(params: any) => <TextField {...params} />}
                  views={["hours"]}
                />
              </div>
            </LocalizationProvider>
            <div className="column">
              {isNotEmptyObj(productList) && (
                <div>
                  <p className=".heading-5 text-bold">{`MS Price: ${productList.find((item) => item.productCode === "1")
                    ?.price || ""
                    }`}</p>
                  <p className=".heading-5 text-bold">{`HSD Price: ${productList.find((item) => item.productCode === "2")
                    ?.price || ""
                    }`}</p>
                </div>
              )}
            </div>
          </div>

          <div className="sale-details-form-container">
            <div className="nozzle-reading-header">Nozzle Readings</div>
            <div className="columns">
              <div className="column">
                <Autocomplete
                  disablePortal
                  id="product-search-box"
                  options={productList}
                  getOptionLabel={(option) =>
                    option.productName ? option.productName : ""
                  }
                  sx={{ width: 250 }}
                  value={selectedProduct}
                  key={selectedProduct?.productCode}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="credit-sale-customer"
                      label="Select Product"
                      variant="outlined"
                      value=""
                    />
                  )}
                  onChange={(event: any, newValue: ProductType | null) => {
                    if (newValue) setSelectedProduct(newValue)
                  }}
                />
              </div>
              <div className="column">
                <TextField
                  type="number"
                  id="daily-starting-reading-1"
                  label="Starting Reading Nozzle-1"
                  fullWidth
                  value={formik.values.startReadingNozzle}
                  variant="outlined"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleChange("startReadingNozzle", e, true)
                  }
                />
              </div>
              <div className="column">
                <TextField
                  type="number"
                  id="daily-starting-reading-1"
                  label="Closing Reading Nozzle-1"
                  fullWidth
                  value={formik.values.closeReadingNozzle}
                  variant="outlined"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    handleChange("closeReadingNozzle", e, true)
                  }
                />
              </div>
              <div className="column is-2 is-flex is-align-items-center">
                {selectedProduct &&
                  formik.values.startReadingNozzle &&
                  formik.values.closeReadingNozzle && (
                    <IconButton onClick={addReading}>
                      <AddCircleIcon />
                    </IconButton>
                  )}
              </div>
            </div>
            {nozzleReadings.length > 0 && (
              <div>
                {nozzleReadings.map((item) => (
                  <div className="columns">
                    <div className="column">
                      <TextField
                        type="number"
                        id="daily-starting-reading-1"
                        label="Starting Reading Nozzle"
                        fullWidth
                        value={item.starting}
                        variant="outlined"
                      />
                    </div>
                    <div className="column">
                      <TextField
                        type="number"
                        id="daily-starting-reading-1"
                        label="Closing Reading Nozzle"
                        fullWidth
                        value={item.closing}
                        variant="outlined"

                      />
                    </div>
                    <div className="column">
                      <TextField
                        type="text"
                        id="daily-starting-reading-1"
                        label="Product"
                        fullWidth
                        value={item.product}
                        variant="outlined"
                      />
                    </div>
                    <div className="column">
                      <IconButton
                        onClick={(): void =>
                          deleteNozzleReading(item.id)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="columns my-6">
              <div className="column is-5 is-flex is-justify-content-center">
                <div>
                  <div className="cash-header text-align-center">
                    Cash Denominations
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">2000: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      label="2000"
                      value={formik.values.cash2000}
                      variant="standard"
                      size="small"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleCashChange("cash2000", e)}
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">500: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash500}
                      label="500"
                      variant="standard"
                      size="small"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleCashChange("cash500", e)}
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">200: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash200}
                      label="200"
                      variant="standard"
                      size="small"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleCashChange("cash200", e)}
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">100: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash100}
                      label="100"
                      variant="standard"
                      size="small"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleCashChange("cash100", e)}
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">50: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash50}
                      label="50"
                      variant="standard"
                      size="small"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleCashChange("cash50", e)}
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">20: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash20}
                      label="20"
                      variant="standard"
                      size="small"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleCashChange("cash20", e)}
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">10: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80 }}
                      value={formik.values.cash10}
                      label="10"
                      variant="standard"
                      size="small"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleCashChange("cash10", e)}
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">Coins: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 80, textAlign: "center" }}
                      value={formik.values.coins}
                      label="Coins"
                      variant="standard"
                      size="small"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleCashChange("coins", e)}
                    />
                  </div>
                  <div className="is-flex is-align-items-baseline">
                    <div className="cash-denomination">Advance Paid: </div>
                    <TextField
                      type="number"
                      className="denomination-input"
                      id="daily-starting-reading-1"
                      sx={{ width: 120, textAlign: "center" }}
                      value={formik.values.advancePaid}
                      label="Advance Paid"
                      variant="standard"
                      size="small"
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleCashChange("advancePaid", e)}
                    />
                  </div>
                  {cashTotal !== 0 && (
                    <div className="cash-total-container is-flex is-align-items-center">
                      <div className="cash-total-title">Cash Total: </div>
                      <div className="cash-total-amount">
                        {cashTotal.toLocaleString("en-IN")}
                      </div>
                    </div>
                  )}
                  <div className="payment-details-container is-flex is-align-items-center is-justify-content-center">
                    <div className="payment-details">Card Sales</div>
                    <TextField
                      type="number"
                      id="daily-starting-reading-1"
                      label="Card Sales"
                      variant="outlined"
                      value={formik.values.cardSales}
                      sx={{ width: 160, textAlign: "center" }}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleChange("cardSales", e, true)}
                    />
                  </div>
                  <div className="payment-details-container is-flex is-align-items-center is-justify-content-center">
                    <div className="payment-details">UPI Sales</div>
                    <TextField
                      type="number"
                      id="daily-starting-reading-1"
                      label="UPI Sales"
                      variant="outlined"
                      value={formik.values.upiSales}
                      sx={{ width: 160, textAlign: "center" }}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ): void => handleChange("upiSales", e, true)}
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="credit-sales-header-container is-flex is-justify-content-space-between is-align-items-center">
                  <div className="credit-sale-title">Pump Test</div>
                  <Button
                    variant="outlined"
                    onClick={(): void => {
                      if (formik.values.testMs || formik.values.testHsd) {
                        return;
                      }
                      setShowTestForm(!showTestForm);
                    }}
                    startIcon={<img src={ADDICON} alt="add-button" />}
                  >
                    Add Test
                  </Button>
                </div>
                {showTestForm && (
                  <div className="test-form-container">
                    <div className="columns">
                      <div className="column is-flex is-justify-content-center">
                        <TextField
                          type="number"
                          id="test-ms"
                          label="Test MS Amount"
                          variant="outlined"
                          value={formik.values.testMs}
                          sx={{ width: 300 }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange("testMs", e, true)}
                        />
                      </div>
                      <div className="column is-flex is-justify-content-center">
                        <TextField
                          type="number"
                          id="test-hsd"
                          label="Test HSD Amount"
                          variant="outlined"
                          value={formik.values.testHsd}
                          sx={{ width: 300 }}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange("testHsd", e, true)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="credit-sales-header-container is-flex is-justify-content-space-between is-align-items-center">
                  <div className="credit-sale-title">Credit Sale</div>
                  <Button
                    variant="outlined"
                    onClick={(): void => {
                      setShowAddCreditModal(true);
                    }}
                    startIcon={<img src={ADDICON} alt="add-button" />}
                  >
                    Add Credit
                  </Button>
                </div>
                <div className="credit-list-table">
                  {creditSalesList.length > 0 && (
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 600, fontFamily: "Roboto" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Sl.No</TableCell>
                            <TableCell align="right">Customer Name</TableCell>
                            <TableCell align="right">Product</TableCell>
                            <TableCell align="right">Quantity</TableCell>
                            <TableCell align="right">Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {creditSalesList.map((row, index) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell scope="row">{row.name}</TableCell>
                              <TableCell align="right">{row.product}</TableCell>
                              <TableCell scope="row">{row.quantity}</TableCell>
                              <TableCell scope="row">{row.amount}</TableCell>
                              <TableCell scope="row">
                                <IconButton
                                  onClick={(): void =>
                                    deleteCreditSale(row.saleId)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
                <div className="expense-header-container is-flex is-justify-content-space-between">
                  <div className="expense-header">Expenses</div>
                  <Button
                    variant="outlined"
                    onClick={(): void => {
                      setShowExpenseForm(!showExpenseForm);
                    }}
                    startIcon={<img src={ADDICON} alt="add-button" />}
                  >
                    Add Expense
                  </Button>
                </div>
                {showExpenseForm && (
                  <div className="expense-form-container">
                    <div className="columns">
                      <div className="column">
                        <DropDownInput
                          key={formik.values.expenseType}
                          value={formik.values.expenseType}
                          items={expenseTypeList}
                          placeholder="Select Type"
                          name="expense-type-name"
                          onChange={handleExpenseTypeSelection}
                          width={190}
                        />
                      </div>
                      {(formik.values.expenseType === "Owners Drawings" ||
                        formik.values.expenseType === "Miscellaneous") && (
                          <div className="column">
                            <TextField
                              id="expense-description"
                              label="Description"
                              variant="outlined"
                              value={formik.values.expenseDescription}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                              ): void =>
                                handleChange("expenseDescription", e, false)
                              }
                            />
                          </div>
                        )}
                      {formik.values.expenseType === "Salary" && (
                        <div className="column">
                          <DropDownInput
                            value={formik.values.salaryEmployeeName}
                            items={employeeList}
                            placeholder="Select Person"
                            name="select-person-name"
                            onChange={handleSalaryEmployeeSelection}
                            width={190}
                            icon={<AccountCircle />}
                          />
                        </div>
                      )}

                      {formik.values.expenseType && (
                        <div className="column">
                          <TextField
                            type="number"
                            id="expense-amount"
                            label="Amount"
                            variant="outlined"
                            value={formik.values.expenseAmount}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ): void => handleChange("expenseAmount", e, true)}
                          />
                        </div>
                      )}

                      <div className="column is-one-fifth is-flex is-align-items-center">
                        {formik.values.expenseDescription &&
                          formik.values.expenseType &&
                          formik.values.expenseAmount && (
                            <IconButton onClick={addExpense}>
                              <AddCircleIcon />
                            </IconButton>
                          )}
                      </div>
                    </div>
                  </div>
                )}
                {expenseList.length !== 0 && (
                  <>
                    <div className="expense-list-title">Expenses List</div>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 600, fontFamily: "Roboto" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="right">Sl.No</TableCell>
                            <TableCell align="right">Description</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {expenseList.map((row, index) => (
                            <TableRow
                              key={row.expenseId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="right"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell align="right">
                                {row.description}
                              </TableCell>
                              <TableCell align="right">
                                {getExpenseType(row.expenseType)}
                              </TableCell>
                              <TableCell align="right">{row.amount}</TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={(): void =>
                                    deleteExpense(row.expenseId)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
                <div className="expense-header-container is-flex is-justify-content-space-between">
                  <div className="expense-header">Payments Recieved</div>
                  <Button
                    variant="outlined"
                    onClick={(): void => {
                      setShowRecievedForm(!showRecievedForm);
                      getAllCustomer();
                    }}
                    startIcon={<img src={ADDICON} alt="add-button" />}
                  >
                    Add Payment
                  </Button>
                </div>
                {showRecievedForm && (
                  <div className="expense-form-container">
                    <div className="columns">
                      <div className="column">
                        <Autocomplete
                          disablePortal
                          id="customer-payment-search-box"
                          options={customersList}
                          getOptionLabel={(option) =>
                            option.customerName ? option.customerName : ""
                          }
                          sx={{ width: 240 }}
                          key={selectedCustomer?.customerId}
                          value={selectedCustomer}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="credit-sale-customer"
                              label="Customer Name"
                              variant="outlined"
                              value={formik.values.customerName}
                            />
                          )}
                          onChange={(
                            event: any,
                            newValue: AddCreditCustomerType | null
                          ) => {
                            handleCustomerSelection(newValue);
                          }}
                        />
                      </div>
                      <div className="column">
                        <TextField
                          type="number"
                          id="payment-amount"
                          label="Amount"
                          variant="outlined"
                          value={formik.values.amount}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ): void => handleChange("amount", e, true)}
                        />
                      </div>
                      <div className="column">
                        <DropDownInput
                          value={formik.values.paymentMode}
                          items={paymentModeList}
                          placeholder="Select Mode"
                          name="payment-mode-name"
                          onChange={handlePaymentModeSelection}
                          width={170}
                        />
                      </div>
                      <div className="column is-2 is-flex is-align-items-center">
                        {selectedCustomer &&
                          formik.values.amount &&
                          formik.values.paymentMode && (
                            <IconButton onClick={addPaymentRecieved}>
                              <AddCircleIcon />
                            </IconButton>
                          )}
                      </div>
                    </div>
                  </div>
                )}
                {paymentRecievedList.length !== 0 && (
                  <>
                    <div className="expense-list-title">
                      Payments Received List
                    </div>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 600, fontFamily: "Roboto" }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell align="right">Sl.No</TableCell>
                            <TableCell align="right">Customer</TableCell>
                            <TableCell align="right">Amount</TableCell>
                            <TableCell align="right">Payment Mode</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {paymentRecievedList.map((row, index) => (
                            <TableRow
                              key={row.paymentId}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                align="right"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell align="right">
                                {row.customerName}
                              </TableCell>
                              <TableCell align="right">{row.amount}</TableCell>
                              <TableCell align="right">
                                {row.paymentMode}
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  onClick={(): void =>
                                    deletePaymentRecieved(row.paymentId)
                                  }
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const AddSaleDetails: React.FunctionComponent = () => {
  return (
    <div>
      <Layout content={<AddSaleDetailsForm />} />
    </div>
  );
};

export default AddSaleDetails;
